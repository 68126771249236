@mixin media-breakpoint-only($size) {
    @if $size == sm {
        @media (max-width: 767px) {
            @content;
        }
    } @else if $size == md {
        @media (max-width: 991px) {
            @content;
        }
    } @else if $size == lg {
        @media (max-width: 1024px) {
            @content;
        }
    } @else if $size == xl {
        @media (max-width: 1200px) {
            @content;
        }
    } @else if $size == xxl {
        @media (max-width: 1400px) {
            @content;
        }
    }
}


