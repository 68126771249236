/* Global Table Design*/ 

 
@mixin TableDataTxt-limit{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
 
.CommonTableContainer{
    .table{ 
      margin-bottom: 0px;
      &.table-inquiry{ 
        thead, tbody{
          tr {
            th{
              .BookingId-th {
                margin-left: 0;
              }
            }
            td {
              .col-bookingId { 
                margin-left: 0;
            }
            }
          }
        }
      }
      thead{
        background: #EDF1F9;
        &.theadSpacetp{
          th{
            padding-top: 14px;
          }
          &.pt-0{
            th{
              padding-top: 7px;
            }
          }
        }
        &.StickyThead{
          position: sticky;
          top: 0;
          z-index: 9;
        }
        &.ThedNew{
          tr{
            th{
              font-size: 16px; 
              color: #8392A8;
              text-transform: inherit;
            }
          }
        }
        tr{
          th{ 
            border: none;
            min-height: 48px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 34px;
            text-transform: initial;
            color: #64748B;
            background: #F1F4F9;
            padding: 7px 16px;
            vertical-align: top;
            .BookingId-th{
              margin-left: 80px;
            }
            .EATNomCnge{
              min-width: 88px;
              display: flex;
              line-height: 24px;
              padding-bottom: 3px;
            }
            .DataWithCheckbx{
              display: flex;
              .containerCheckbx{
                padding: 0; 
              }
              .CheckbxTextSpace{
                margin-left: 56px;
                @media screen and (max-width: 767px) {
                  margin-left: 30px; 
                }
              }
            } 
            .TheadeContent{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .shortingIcons{
                margin-left: 4px; 
                position: relative;
                &.descending-icon{
                 svg{
                   path{
                     &:first-child{
                       fill: #474747;
                     }
                   }
                 }
                }
                &.ascending-icon{
                  svg{
                    path{
                      &:last-child{
                        fill: #474747;
                      }
                    }
                  }
                 }             
                &.active{
                  width: 16px;
                  height: 16px; 
                  line-height: 16px;  
                  border-radius: 50%;
                }
                svg{
                  display: flex;
                  cursor: pointer;
                }
              }
            }
            &:first-child{
              padding-left: 16px;
              @media screen and (max-width: 767px){
                padding-left: 12px;
              }
            }
            &:last-child{
              padding-right: 16px;
              @media screen and (max-width: 767px){
                padding-right: 12px;
              }
            }
          }
        }
      }
      tbody{
        border: none;
        &.borderrow{
         &:last-child{
          border-bottom: 1px solid #DBE3ED; 
         }
        }
        tr{
          border-top: 1px solid #DBE3ED; 
          cursor: pointer; 
          &:last-child{
            border-bottom: none;
            td{ 
              .TootipDropDiv{
                bottom: 0 !important;
                margin-bottom: 55px !important;
                .AfetrBeforeAr{
                  &::before { 
                    top: initial;
                    bottom: -5px; 
                  }
                  &::after { 
                    top: initial;
                    bottom: -0.4px; 
                }
                }
              }
              .TransshipmentVessel{
                .TootipDropDiv{ 
                  margin: 36px 0 !important;
                }
              }

              .ToolTipCargoHld{
                top: inherit;
                bottom: 0;
                margin-bottom: 30px !important;
                &::before{
                  top: inherit;
                  bottom: -5px;
                }
                &::after{
                  top: inherit;
                  bottom: 0.4px;
                }
              }
              .td-in-drp{
                ng-dropdown-panel{
                  bottom: 32px;
                }
              }
            }
          }
          &:nth-last-child(2){ 
            td{ 
              .ToolTipCargoHld{
                top: inherit;
                bottom: 0;
                margin-bottom: 30px !important;
                &::before{
                  top: inherit;
                  bottom: -5px;
                }
                &::after{
                  top: inherit;
                  bottom: 0.4px;
                }
              }
              .TransshipmentVessel{
                .TootipDropDiv{ 
                  margin: 36px 0 !important;
                }
              }
              
              .TootipDropDiv{
                bottom: 0 !important;
                margin-bottom: 55px !important;
                .AfetrBeforeAr{
                  &::before { 
                    top: initial;
                    bottom: -5px; 
                  }
                  &::after { 
                    top: initial;
                    bottom: -0.4px; 
                }
                }
              }
              .td-in-drp{
                ng-dropdown-panel{
                  bottom: 32px;
                }
              }
            }
          } 

          &:nth-last-child(3){ 
            td{ 
              .TootipDropDiv{
                bottom: 0 !important;
                margin-bottom: 55px !important;
                .AfetrBeforeAr{
                  &::before { 
                    top: initial;
                    bottom: -5px; 
                  }
                  &::after { 
                    top: initial;
                    bottom: -0.4px; 
                }
                }
              }
              .TransshipmentVessel{
                .TootipDropDiv{ 
                  margin: 36px 0 !important;
                }
              }
            }
          }
          &:nth-last-child(4){ 
            td{ 
              .TootipDropDiv{
                bottom: 0 !important;
                margin-bottom: 55px !important;
                .AfetrBeforeAr{
                  &::before { 
                    top: initial;
                    bottom: -5px; 
                  }
                  &::after { 
                    top: initial;
                    bottom: -0.4px; 
                }
                }
              }
              .TransshipmentVessel{
                .TootipDropDiv{ 
                  margin: 36px 0 !important;
                }
              }
            }
          }
          &:first-child{
            border-top: none;
          }
          &.childRow{
            border-top:none; 
            border-bottom:none; 
          }
          &:hover{
            background:var(--neutralN10-color);
            td{
              background:var(--neutralN10-color);
            }
          }
          &.selected-row{
            background:var(--neutralN10-color);
            td{
              background:var(--neutralN10-color);
            }
          }
          &.UnreadTable{
            td{
              .UnredDot{
                visibility: visible;
                opacity: 1;
              }
              .UnreadRow{
                font-weight: 700;
              }
            }
          }
          td{
            border: none;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            height: 65px; 
            vertical-align: middle;
            color: var(--theme-text-color-01);
            position: relative; 
            padding: 18px 16px;
            &.checked-td{
              background: var(--neutralN10-color);
            }
            .truncateHeight{
              &.truncate-text{
                &:hover{
                  position: absolute;
                  left: 11px; 
                  z-index: 9999;
                  transform: translate(0, -50%);
                  min-width: max-content;
                  background: #fff;
                  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
                  border-radius: 4px;
                  padding: 2px 5px; 
                }
              }
            }
            .col-bookingId{
              width: 100%; 
              max-width: 112px;
              margin-left: 80px;
              &.InquiryVslHld{
                max-width: fit-content; 
                height: 24px;
                position: relative;
                .truncateHeight{ 
                  max-width: fit-content; 
                  @media screen and (max-width: 1300px) {
                    white-space: nowrap;
                  }
                  &:hover{
                    top: 11px;
                  } 
                }
              }
              .checkmark{
                left: -80px;
              }
              .truncate-text{
                max-width: 92px;
              }
            }  
            .DataWithCheckbx{
              display: flex;
              .containerCheckbx{
                padding: 0; 
              }
              .CheckbxTextSpace{
                margin-left: 56px;
                @media screen and (max-width: 767px) {
                  margin-left: 30px; 
                }
              }
            }
            .UnredDot{ 
              min-width: 8px;
              height: 8px;
              background: #CBD4E1;
              border-radius: 50%;
              display: inline-flex;
              margin: 0 8px 0px 0; 
              visibility: hidden;
              opacity: 0;
            }
            .plusnumb{
              color: var(--text-color-defolt);
              border: 1px solid var(--text-color-defolt);
              padding: 0px 4px;
              border-radius: 4px;
              font-size: 12px;
              position: relative;
              top: -1px;
            } 
            .aligntooltip{
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 50%;
              span{
                .infosp{ 
                  top: 1px !important;
                }
              }
            }
            .Co2Value{
                display: flex; 
                justify-content: flex-start;
                min-width: 132px;
            }
            a{
              font-weight: normal;
              color: var(--theme-text-color-01);
              text-decoration: none; 
              &.DownloadTxt{
                color: var(--text-color-defolt);
                text-decoration: underline;
                &.disabled{
                  color: var(--theme-text-color-01) !important;
                  text-decoration: none;
                }
              }
            } 
            .TestData{
                padding: 0px !important;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
                line-height: 24px;
              span{
                em{
                  font-style: normal;
                  color: #999999;
                }
              }
            }
            .textcolorchnge{
              span{
                em{
                  font-style: normal;
                  color: #B8BFCA;
                }
              }
            } 
            &.Colspandta{
              padding: 0px !important;
            }  
            .D_flex{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              svg{
                margin-left: 5px;
              }
            } 
            .InfoToolTip{
              position: relative;
              display: flex; 
              &.pl_7{
                padding-left: 7px;
              }
              svg{
                display: flex; 
              }
            }
  
            .InnerTableDta{
              width: 100%;
              tr{
                border-bottom: none;
                td{
                  padding: 0.5rem 0.5rem;
                  &:first-child{
                    padding-left: 0.5rem; 
                  } 
                } 
              } 
            }
            .VnameWdth{
              width: 80px;
              display: flex;
              align-items: center;
              &:hover{
                width: auto;
                min-width: max-content;
                background: #fff;
                box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
                border-radius: 4px; 
                position: absolute;
                left: 16px;
                top: 18px;
                padding-right: 5px;
                z-index: 9;
                .TootipDropDiv{
                  display: block;
                  margin-top: 16px;
                } 
              }
              .truncate-text{
                position: static;
                padding: 2px 5px;
                box-shadow: none;
                pointer-events: none;
              }
              .TransshipmentVessel { 
                left: 0;
             } 
            }
            .TransshipmentVessel{
              position: relative;
              left: 8px;
              .TootipDropDiv{
                min-width: max-content;
                left: -105px;
                display: none;
              }
            }
          } 
          .dropChanePosition{
            .ToolTipCargo{ 
              top: inherit;
              bottom: 0;
              margin-bottom: 51px;
            } 
            .CargoEtaInfo{
              top: inherit;
              bottom: 0;
              margin-bottom: 29px; 
            }
          }            
        }
      }
      &.Table--2{
        tbody{ 
            tr{
              td{
                padding-top: 20px;
                padding-bottom: 20px;
                height: auto;
              }
            }
          } 
      }
      .col-165{
        min-width: 165px;
      }
      .col-140{
        min-width: 140px;
      }
      .col-150{
        min-width: 150px;
      }
      .col-125{
        min-width: 125px;
      }
      .col-110{
        min-width: 110px;
      }
      .col-mw-110{
        max-width: 110px;
      }
      .col-100{
        min-width: 100px;
      }
      .col-80{
        min-width: 80px;
      } 
      .col-60{
        min-width: 60px;
      }
      .col-200{
        min-width: 200px;
      }
    } 
    .ng-scrollbar-thumb{
      background-color: #DBE3ED;
    } 

    scrollbar-x{
      height: auto !important;
      border-bottom: 1.5px solid #fff;
      &.scrollbar-control>.ng-scrollbar-track {
        height: 8px !important; 
        background-color: #F6F8FC;
      }
      top: 156px !important;
      position: sticky !important;
    }
    .multifilter{
      scrollbar-x { 
        top: 220px !important;  
      }
    }
  }


  @mixin common-prp-ttp{
    top: 0 !important;
    margin-top: 54px !important;
    bottom: inherit !important;
  }

  @mixin common-prp-ttp-before{
    top: -7px;
    bottom: inherit;
  }

  @mixin common-prp-ttp-after{
    top: 0;
    bottom: inherit;
  }

 
  
.CommonTableWithMobileBx{
  .CommonTableContainer{
      max-height: 840px;
      overflow-y: auto;
      @media screen and (max-width: 992px) {
          display: none;
      }
      
          border: 1px solid var(--theme-light-grey-color);
          border-radius: 4px;
          @media screen and (max-width: 1024px) {
              overflow-x: auto;
              .table{
                  width: 1100px;
              }
          }
          thead{
              background: var(--white-color) !important;
              tr{
                  border-bottom: 1px solid var(--theme-light-grey-color); 
                  th{
                      color: #8392A8;
                      font-weight: 700;
                      vertical-align: middle;
                      letter-spacing: 0.2px;
                      &:nth-child(4){
                          padding-right: 30px;
                      } 
                  }
              }
          }
      tbody{
          tr{
              border-bottom: 1px solid var(--theme-light-grey-color) !important;
              cursor: auto;
              &:last-child{
                  border-bottom: none !important;
              }
              td{
                  height: 72px;
                  line-height: 40px;
                  color: var(--front-color-defolt);
                  letter-spacing: 0.2px;
                  &:nth-child(4){
                      padding-right: 30px;
                  }
                  .CapacityRight{ 
                    width: 140px;
                    display: flex;
                    justify-content: end;
                  }
                  span{
                      line-height: 24px;
                      .BorderDotted{
                          display: inline-block;
                          
                      }
                  } 
                  a{ 
                      color: var(--front-color-defolt02) !important; 
                  } 
                  
                  .newtab-extlink{
                      a{
                          display: flex;
                          align-items: center;
                          svg-icon{
                              margin-left: 8px;
                          }
                      }
                  }
              }
          }
      } 
  }

  .MobileTableBoxHolder{
      display: none;
      @media screen and (max-width: 992px) {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
          &:last-child{
              margin-bottom: 0;
          }
      }
      .MobileTableBox{
          background: var(--white-color);
          border: 1px solid var(--front-color-defolt05);
          padding: 16px;
          margin-bottom: 16px;
          &:last-child{
              margin-bottom: 0px;
          }
          .MobileTableBoxHead{
              .heading03{
                  font-size: 18px;
                  line-height: 20px;
                  margin-bottom: 16px;
              }
              span{
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px; 
                  letter-spacing: 0.02em;
                  text-transform: uppercase; 
                  color: #8392A8;
                  display: flex;
                  flex-direction: column;
              }
              p{
                  font-size: 16px;
                  line-height: 28px;  
                  color: var(--front-color-defolt);
                  margin: 0;
              }
              .ContentIn{
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between; 
                  padding-bottom: 16px;
                  .RightCnt{
                      text-align: right;
                  }
              }
              .FtrBtnSecMobile{
                  padding-top: 16px;
                  display: flex;
                  justify-content: center;
                  border-top: 1px solid var(--front-color-defolt05);
                  span{
                      margin-right: 52px;
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                      &:last-child{
                        margin-right: 0;
                      }
                      &.Icon-map{
                          svg{
                              height: 24px;
                              display: flex;
                          }
                      }
                      a{ 
                          letter-spacing: 0.002em;
                          border-bottom: 1.5px dashed;
                          color: var(--front-color-defolt02);
                          margin-top: 4px; 
                          text-transform: capitalize;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 28px;
                          &.small-IconSpce{
                              margin-top: 8px;
                          }
                      }
                  }
              }
          }
      } 
  }
} 

.BoxHead-Skeleton{
  height: 250px;
}
/* Global Table Design End*/




/* Stolt Custom Table View */

  .TableViewInfo{
      background: #FFFFFF;
      margin-bottom: 60px;
      &.TableViewDetails{
        margin-bottom: 48px;
      }
      &.tracktable{
        margin-bottom: 30px;
      }
    .TableViewInfoIn{ 
      .TableViewInfoInHead{ 
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 16px; 
          min-height: 48px; 
          background: #EDF1F9; 
          .datavalue{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase; 
            color: #576375;
            min-width: 264px;
            @media screen and (min-width: 1410px) {
              min-width: 150px;
              max-width: 150px;
            } 
            @media screen and (max-width: 1400px) {
              min-width: 120px;
              max-width: 120px;
            }  

            
            &.LastUpdatedCol{ 
              @media screen and (min-width: 1600px) {
                min-width: 400px;
                max-width: 400px;
              } 
              @media screen and (max-width: 1450px) {
                min-width: 250px;
                max-width: 250px;
              } 
              @media screen and (max-width: 1400px) {
                min-width: 200px;
                max-width: 200px; 
              }
            }
            display: flex; 
            margin-right: 40px;
            &.nominationwidth{
              min-width: 368px;
              @media screen and (max-width: 1300px) {
                min-width: 270px;
              }  
            }
            &:last-child{
              margin-right: 0;
              gap: 4px;
            }
          }
      }
      .TableViewInfoInBody{
        .TableView-data{ 
            border-bottom: 1px solid #E9EEF2;
            padding: 21px 16px;
            display: flex;
            align-items: center;
            &:last-child{
              border: none;
            }
            .datavalue{
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 21px; 
              color: #27364B;
              min-width: 264px;
              display: flex;
              margin-right: 40px;
              &.WrapDataCstm{
                flex-wrap: wrap;
                gap: 8px;
              }
              @media screen and (min-width: 1410px) {
                min-width: 150px;
                max-width: 150px;
              } 
              @media screen and (max-width: 1400px) {
                min-width: 120px;
                max-width: 120px;
              }  
              
              &.LastUpdatedCol{ 
                @media screen and (min-width: 1600px) {
                  min-width: 400px;
                  max-width: 400px;
                } 
                @media screen and (max-width: 1450px) {
                  min-width: 250px;
                  max-width: 250px;
                }
                @media screen and (max-width: 1400px) {
                  min-width: 200px;
                  max-width: 200px; 
                }  
              } 
              &.nominationwidth{
                min-width: 368px;
                @media screen and (max-width: 1300px) {
                  min-width: 270px;
                }
                .mid-Align{
                  position: relative;
                  top: 2px;
                }
              }
              &:last-child{
                margin-right: 0;
              } 
              .cargo-delay{
                margin-left: 0;
              }

            }
            .TableLaycanDate{
              .CargoLaycanCol{
                  margin-top: 8px;
              }
              .CargoLaycanColBody{
                row-gap: 4px;
                display: flex;
                flex-direction: column;
                margin-top: 4px;
                .ToolTipCustomHover{
                  .IneerCntTt{
                    top: 46px;
                  }
                }
                .itemcargolcn{
                  display: flex;
                  flex-direction: column;
                }
              }
              span{
                color: var(--theme-text-color-01) !important;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                &.titleCrgolcn{
                  color: var(--theme-text-color-02) !important;
                }
                &.valueBld{
                  font-weight: 500;
                }
              }

            }
        } 
      }

    }
  }
 
/* Stolt Custom Table View */



/* Snl Table Customization */
.snl-table-customized{
  snl-row{
    &.UnreadRow{
      .UnredDot{ 
        visibility: visible;
        opacity: 1;
      }
      .UnReadId{
        font-weight: 700;
      }
    }
    &.RowUnredDotMngr{
      .UnredDot{ 
        display: none; 
        margin-left: var(--spacing-4);
      }
      &.UnreadRow{
        .UnredDot{ 
          display: block; 
        } 
      }
    }
    &.selected-row{ 
      snl-td{
        background:var(--neutral-10);
      }
    }
    &.childRow{
      snl-td{  
        border-top:none; 
      } 
    }
    &:hover{
      snl-td{
        background: var(--neutral-10);
        cursor: pointer;
      }
    }
    &.th-height-custm{
      snl-th{
        background-color: var(--neutral-10);
        .th__wrapper{
          height: auto;
          min-height: var(--spacing-72); 
          display: flex;
          align-items: flex-start;
          padding: var(--spacing-12) 0;
        }
      }
    }
    snl-th{
      .BookingId-th{
        margin-left: var(--spacing-80);
      }
      .ms__48{
        margin-left: var(--spacing-48);
      }
      .shortingIcons{
        margin-left: var(--spacing-4);  
        &.descending-icon{
         svg{
           path{
             &:first-child{
               fill: var(--font-color-primary);
             }
           }
         }
        }
        &.ascending-icon{
          svg{
            path{
              &:last-child{
                fill: var(--font-color-primary);
              }
            }
          }
         }  
      }
      &.text-flex-end{
        .section-title{
          justify-content: flex-end;
        }
      }
      &.text-flex-center{
        .section-title{ 
          justify-content: center;
        }
      }
    }
    &.sticky-tbl-hdr{
      position: sticky;
      top: 0;
      z-index: 99;
    }
    snl-td{
      position: relative;
      border-bottom: none;
      border-top: var(--spacing-1) solid var(--neutral-9); 
      .nowWrpTxt{
        white-space: nowrap;
      }
      .text__primary{ 
        color: var(--font-color-primary) !important;
        text-decoration: none; 
      } 
      .text__Dprimary{
        color:var(--primary-default);
      }
      .text__semantic5{
        color: var(--semantic-5);
      }
      .border-dash-b{
        border-bottom: 1.5px dashed currentColor;
        text-decoration: none;
      }
      .UnredDot{ 
        margin-right: var(--spacing-8); 
        visibility: hidden;
        opacity: 0;
      }
      &.NoRecordAvl{ 
        background-color: transparent;
        border: 0;
        position: sticky;
        left: 43%; 
        cursor: text;
      }
      .col-bookingId{
        snl-checkbox{
          margin-right: var(--spacing-80);
          &.ms__64{
            margin-right: var(--spacing-64);
          } 
          &.ms__32{
            margin-right: var(--spacing-32);
          } 
        }
      } 
      .truncateHeight{
        position: relative;
        z-index: 99;
        &.truncate-text{
          &:hover{
            position: absolute;
            left: 11px; 
            z-index: 9999;
            transform: translate(0, -50%);
            min-width: max-content;
            background: var(--neutral-12);
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
            border-radius: var(--spacing-4);
            padding: var(--spacing-2) 5px; 
          }
        }
        &.truncate-text-dots{ 
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
          &:hover{
            position: absolute;
            left: -5px;
            z-index: 9999;
            transform: translate(0, -50%);
            min-width: max-content;
            background: var(--neutral-12);
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
            border-radius: var(--spacing-4);
            padding: var(--spacing-2) 5px;
            margin-top: 12px;
            top: 0;
          }
        }
      }
      .TransShipmentVesselCl{
        display: flex;
        align-items: center;
        .VesselNameHst{
          display: flex;
          align-items: center;
          gap: 4px;
          .VesselNameTs{
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            max-height: 30px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
      .snl-switch-cstm{
        height: var(--spacing-24);
        display: flex;
        .switch__slider{
          padding: 0 var(var(--spacing-8)) 0 10px;
          gap: 0;
        }
        .switch__slider--on{ 
          position: relative;
          right: 7px;
        }
      }
    }
    .InquiryVslHld{
      display: flex;
      align-items: center;
    }
    .container__checkbox{
      input[type=checkbox]{
        &:disabled{
          cursor: not-allowed !important;
          border: var(--border-width-2) solid var(--primary-disabled) !important;
        }
      }
    }
    .col-165{
      min-width: 165px;
    }
    .col-140{
      min-width: 140px;
    }
    .col-mw-140{
      max-width: 140px;
    }
    .col-150{
      min-width: 150px;
    }
    .col-125{
      min-width: 125px;
    }
    .col-mw-125{
      max-width: 125px;
    }
    .col-110{
      min-width: 110px;
    }
    .col-100{
      min-width: 100px;
    }
    .col-102{
      min-width: 102px;
    }    
    .col-mw-110{
      max-width: 110px;
    }
    .col-80{
      min-width: 80px;
    } 
    .col-82{
      min-width: 82px;
    } 
    .col-mw-80{
      max-width: 80px;
    }
    .col-60{
      min-width: 60px;
    }
    .col-200{
      min-width: 200px;
    }
    .col-220{
      min-width: 220px;
    }
    
    .col-fixed114{
      width: 114px;
      min-width: 114px;
      max-width: 114px;
    }
    .col-fixed220{
      width: 220px;
      min-width: 220px;
      max-width: 220px;
    }
    .col-fixed182{
      width: 182px;
      min-width: 182px;
      max-width: 182px;  
    }
    .col-min204{
       min-width: 204px;
    }
    .col-min118{
      min-width: 118px;
   }
  }
  &.Vessel-inquiry{
    snl-row{
      snl-td{
        &:last-child{ 
          .truncate-dots-hld{
            .truncate-text-dots{
              &:hover{
                left: inherit;
                right: -5px;
              }
            }
          }
        }
      }
      &.childRow{ 
        snl-td{
             &:first-child{
              .td__wrapper{
                .data-text{
                  .text{
                    margin-left: 80px;
                  }
                }
              }
             }
        }
      }
    }
  }
  &.user-mng-table{
    .table__wrapper{
      snl-row{
        ng-select{
          ng-dropdown-panel{
            .ng-dropdown-panel-items{
              .ng-option{
                &.ng-option-selected{
                  snl-checkbox{
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
        &:last-child, &:nth-last-child(2){ 
          snl-td{
            .form-control{
              ng-select{
                ng-dropdown-panel{
                  top: -80px;
                  height: 120px;
                  overflow-y: auto;
                }
              }
            }
          }
        }
      }
    }
  }
  &.EtbInternalTable{
    snl-row{
      snl-td{
      &:nth-child(5), &:nth-child(6){
        .td__wrapper{
          .text__primary{
            white-space: nowrap;
          }
        }
      }
    }
  }
}
}


/* Scrollbar x Customization */
.snl-table-holder{
  scrollbar-x{
    height: var(--spacing-8) !important; 
    border-bottom: var(--spacing-2) solid var(--neutral-12); 
    top: 0 !important;
    &.scrollbar-control>.ng-scrollbar-track { 
      background-color: var(--neutral-11);
      height:var(--spacing-8) !important;
    } 
    .ng-scrollbar-thumb{
      background-color: var(--neutral-9);
    } 
  }
  scrollbar-y{
    right: -4px !important;
    &.scrollbar-control>.ng-scrollbar-track { 
      background-color: var(--neutral-11); 
    } 
    .ng-scrollbar-thumb{
      background-color: var(--neutral-9);
    } 
  }
  .sticky-scrollbar-x{
    scrollbar-x {
      top: 157px !important;
      position: sticky !important; 
    }
    &.multifilter{
      scrollbar-x { 
        top: 221px !important;  
      }
    }
  }
  .Notsticky-scrollbar-x{
    scrollbar-x {
      position: relative !important;
      margin-top: -8px;
    } 
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
}


/* Snl Tooltip Customization */
.snl-tooltip-custmzd{ 
    min-width: 216px;
    max-width: 400px;
    &.mw_maxCnt{
      max-width: max-content;
    }
    &.LegendsInTp{
      min-width: 137px;
      max-width: 137px;
       ul{
        li{
          color: var(--font-color-primary) !important;
          display: flex;
          align-items: center;
          gap: var(--spacing-8);
          border-bottom: var(--spacing-1) solid #ddd;
          padding-bottom: var(--spacing-8);
          margin-bottom: var(--spacing-8);
          &:last-child{
            border: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
          span{
            &.StraightLine{
              height: 18px;
              width: 3px; 
              display: inline-flex;
              align-items: center;
              justify-content: center;
              &.EtaClr{
                background-color: #DD5050;
              }
              &.EtdClr{
                background-color: #9E1023;
              }
              &.todaClr{
                width: 1.5px; 
                background-color: var(--primary-default);
              }
            }
          }
        }
      }
    }  
    &.static-tooltip{
      max-width: 280px;  
    }
    &.gsearch-cargo{
      min-width: auto;
    }
    &.GapTooltipUserM{
      display: flex;
      flex-direction: column;
      gap: var(--spacing-4);
      .section-title, .__CargoList {
        span{
          width: 85%;
          &:first-child{
            min-width: 116px;
            max-width: 116px;
            width: 116px;
          }
        }
      }
    }
   .c-gap-24{
    column-gap: var(--spacing-24);
   }
   .__CargoList{
    color: var(--font-color-primary);
   }
   .body-tbd{
    span{
      color: var(--font-color-primary);
    }
  }
  .col-main{
    display: flex;
    column-gap: 10px;
    flex-direction: column;
    row-gap: var(--spacing-2);
    .body-tp-row{
      display: flex;
      column-gap: var(--spacing-8);
      border-bottom: var(--spacing-1) solid var(--neutral-10);
      padding: var(--spacing-2) 0;
    }
  }
  .TooltipLaycanBody{
    display: flex;
    flex-direction: column;
    gap: 16px;
    .item{
      display: flex;
      gap: 24px;
      justify-content: space-between;
      .name{
        span{
          color: var(--font-color-primary);
        }
        &.headerTp{
          font-weight: 700;
        }
      }
    }
  }
  &.mw_maxCnt{
    max-width: max-content;
  }
}
.calendar-tooltip{
  .snl-tooltip-custmzd{
    min-width: 170px;
  }
}
.TotalMGO__tp{
  margin-left: auto;
  @media screen and (max-width: 991px) {
    margin-left: inherit;
  }
} 
snl-tooltip-popover{
  @media screen and (max-width: 991px) {
    display: none;
  } 
}

 
/* Snl pills Customization */
snl-pills{
  .container__pills{
    height: 24px;
    display: flex;
    align-items: center;
  }
}