
// Import breakpoints mixin from mixin-breakpoints.scss
@import "src/styles/common/mixin-breakpoints.scss";


@mixin disabled-btn {
  cursor: not-allowed; 
  mix-blend-mode: normal; 
  color: var(--clr-white-common);
}

// Stolt common buttons 
button{
  &:disabled{ 
    @include disabled-btn;
    opacity: .4; 
  }
  &.StoltBtnBorderOnly{ 
    &.DfltStroke{
      svg{
        path{
          stroke: inherit;
        }
      }
    }
  }
}

snl-button{
  .snl-button{
    &:disabled{  
      opacity:1; 
      cursor: not-allowed !important;
    }
  }
  &.DeleteBtnBG {
    .snl-button[type^=primary]{
      background-color: var(--clr-theme-warn);
      border-color: currentColor;
      &:disabled{ 
        background-color: var(--primary-disabled) !important;
        border-color: currentColor;
      }
      &:hover{
        background-color: var(--clr-theme-warn);
        border-color: currentColor;
      }
    }
  }
  &.shareIcon{
    .share-icn{
      svg{
        path{
          stroke:var(--primary-default);
        }
      }
    }
  }
}




.disabled{
  color: var(--front-color-defolt06) !important;
  cursor: not-allowed;  
}

.StoltBtnIconLarge{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;  
    @media screen and (min-width: 1200px) { 
      padding: 8px 32px; 
    }
    min-width: 143.48px;
    min-height: 48px; 
    background: var(--text-color-defolt);
    border: 1px solid var(--text-color-defolt);
    color: var(--background--white);
    mix-blend-mode: normal;
    border-radius: 4px; 
    font-weight: 700;
    &.Yes-Quit {
      background: var(--clr-theme-warn);
      border-color: currentColor;
    }
    &.disabledBtn { 
      @include disabled-btn;
      color: var(--clr-white-common);
    }
    &.active{
      background: var(--text-color-defolt);
      border: 1px solid var(--text-color-defolt);
      color: var(--clr-white-common);
      svg{
        path{
          fill: var(--clr-white-common);
        }
      }
    } 
    &:disabled{
        background-color: var(--primary-disabled);
        border-color: var(--primary-disabled);
        opacity: 1;
    }
    &.SvgColor{
      svg{
        path{
          fill: var(--clr-white-common);
        }
      }
    }
}
.iconspace{
    margin-right: 8px;
    display: flex;
  }

.StoltBtnBorderOnly{
  border: 1px solid var(--text-color-defolt);
  color: var(--text-color-defolt);
  background: var(--background--white);
  &:disabled{ 
    color: var(--primary-disabled);
    border-color: var(--primary-disabled);
    background: var(--background--white);
    svg{
      path{
        stroke:var(--primary-disabled);
      }
    }
  }
}

.StoltBtnTertiary{
    border:none;
    color: var(--text-color-defolt);
    background: var(--background--white);
    text-decoration-line: underline;
  }

  .StoltBtn-no-bg{
    border:none;
    color: var(--text-color-defolt);
    background:none; 
  }

.BtnNoBgArrow{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  border-bottom: 3px solid var(--clr-secondary-public);
  display: inline-flex;
  align-items: center;  
  color: var(--clr-white-common);
  font-family: var(--font-family-02); 
  text-decoration: none;
  position: relative;
  @include media-breakpoint-only(md) {
    font-size: 16px;
    line-height: 28px;
  }
  &:hover{
    color: var(--clr-white-common);
  }
  img{
    margin-left: 10px;
  }
}
.DisabledBtnMain{
    display: flex;
    align-items: center; 
    .disabledBtn{
      @include disabled-btn;
      opacity: .4;
      min-width: 345px;
      @include media-breakpoint-only(md) {
        min-width: 100%;
      }
      &.__disabledBtnSus{
        background-color: var(--primary-disabled);
        border-color: var(--primary-disabled);
        opacity: 1;
      }
    }
  .notifText{ 
    margin-left: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--theme-text-color-04); 
  }
}
.DisabledBtnMainSusbtn{
  gap: 16px;
  .notifText{
    @media screen and (max-width: 1500px) { 
       max-width: 610px;
    }
    @media screen and (min-width: 993px) and (max-width: 1024px) {
      max-width: 370px;
    }
  }
}
.mw-auto{
  min-width: auto;
} 
 
// View changer button
.TabelViewChangers{
    width: 99px; 
    @include media-breakpoint-only(sm) {
      width: 97px;
    }
    height: 48px; 
    background: var(--clr-white-common);
    border: 1px solid var(--text-color-defolt);
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    span{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      font-weight: 700;
      color: var(--text-color-defolt);
      cursor: pointer;
      font-size: 16px;
      line-height: 24px; 
      &:first-child{
        border-radius: 6px 0px 0px 6px;
      }
      &:last-child{
        border-radius: 0px 6px 6px 0px;
      }
      svg{
        display: flex;
        margin-right: 4px;
        path{
          fill:var(--text-color-defolt);
        }
      }
      &.mapActive{
        background: var(--text-color-defolt);
        color: var(--clr-white-common);
        svg{
          path{
            fill:var(--clr-white-common);
          }
        }
      }
    }
  }

.ViewChanger{
  border-radius: 4px;
  display: flex;
  width: fit-content;
  overflow: hidden;
  border: 1px solid var(--clr-secondary-public);
  .RadioHld{
    .TypeRadio{
      padding: 12px;
      height: 48px;  
      text-align: center;
      letter-spacing: 0.2px; 
      font-weight: 700;
      display: inline-flex;
      color: var(--clr-secondary-public);
      cursor: pointer;
      position: relative;
      .InvisibleRadio{
        width: 100%;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
      }
      &.active{
        background: var(--clr-secondary-public);
        color: var(--clr-white-common);
      }
    }
  } 
}


  .loading-buffering{
    position: absolute;
    background: var(--text-color-defolt);
    width: 100%;
    height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .BtnShareDownHolder{ 
    .BtnShareDownMn{
      display: flex;
      gap: var(--spacing-16);
      snl-button {
        .snl-button{
          svg-icon{
            svg{
              path{
                stroke: var(--neutral-12);
              }
            }
          } 
        }
      }
    } 
  }