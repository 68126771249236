
// Import breakpoints mixin from mixin-breakpoints.scss
@import "src/styles/common/mixin-breakpoints.scss";


// Stolt Tabs for private pages
.tab-slider--nav{  
  width: 100%;  
  .tab-slider--tabs{  
    min-height: 44px;
    border-bottom: 1px solid var(--neutral-9);
    width: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-16) var(--spacing-16) 0px var(--spacing-16) !important;
    &.MonitorBknTabs{
      snl-page-tab{
        .page-tab {
          @media screen and (max-width: 992px) {
          padding-bottom: 2px !important;
          }
        }
      }
    }
    li, .page-tab{  
      color: var(--font-color-secondary);
      margin-right: var(--spacing-32);
      position: relative;
      padding: var(--spacing-8) 0px;
      cursor: pointer; 
      &::before{
        position: absolute;
        content: ""; 
        background:#dbdde0;
        width:0;
        height: var(--spacing-4);
        bottom: 0;
        border-radius: 3px 3px 0px 0px;
        -webkit-transition:  width .5s;
        transition:  width .5s;
        @media screen and (max-width: 992px) {
          content: none;
        }
      }
      &:hover{
        &::before{
        width: 100%;
        }
      }
      &.tabs-skeltonCls{
        padding: var(--spacing-8) 0px;
        border-radius: 50px; 
        min-width: 85px;
        margin-bottom: var(--spacing-16);
        @include media-breakpoint-only(md) {
          margin: 0 var(--spacing-16) 0 0 !important;
          padding: var(--spacing-12) 0 var(--spacing-16) 0 !important;
        }
        &::before{
          content: none;
        }
        @media screen and (max-width: 340px) { 
          min-width: 70px;
        }
      }

      @include media-breakpoint-only(md) { 
        padding: var(--spacing-24) var(--spacing-16) var(--spacing-16) 0;
        &:last-child{
          margin-right: 0px !important;
        }
      }
      
      @include media-breakpoint-only(sm) { 
        display: flex;
        flex-direction: column;
      }
      &.active{
        color:var(--primary-default);
        font-weight: 500;
        &::before{
           position: absolute;
           content: ""; 
           background:var(--primary-default);
           width: 100%;
           height: 4px;
           bottom: 0;
           border-radius: 3px 3px 0px 0px;

        }
      }
      .page-tab__content{
        padding-bottom: 0;
        &.page-tab--selected{
          font-weight: 500;
        }
      }
      .page-tab__container--selected{
        top: var(--spacing-8);
        position: relative;
      }
    }
  }
  &.detail-page-tab-slider--nav{ 
      .tab-slider--tabs{
        @include media-breakpoint-only(md) {
          &::before, &::after{
            display: none !important;
          }
        }
        li {
          @include media-breakpoint-only(sm) {
            margin-right: 12px;
            font-size: 13px;
            padding: 10px 0;
            &:last-child{
              margin-right: 0;
            }
          }
        }
      } 
  }
}

.detail-page-tab-slider--nav{
  .tab-slider--tabs{
    @include media-breakpoint-only(sm) { 
      overflow-x: auto;
      display: flex;
      scrollbar-width: none;
      justify-content: flex-start !important; 
      padding-bottom: 12px !important;
    }
    snl-page-tab{
      .page-tab{ 
        @include media-breakpoint-only(md) {
          padding: 10px 0px 8px 32px;
          &::before{
            display: none;
          }
          .page-tab__container--selected{
            bottom: 0; 
          }
        }
        @include media-breakpoint-only(sm) {
          padding: 10px 0px 8px 24px; 
          font-size: 16px;
          white-space: nowrap;
          font-weight: 700 !important;
          .page-tab--selected{
            font-weight: 700 !important;
          }
        }
      } 
      &:first-child{
        .page-tab{
          @include media-breakpoint-only(sm) {
            padding-left: 0;
          }
        }
        
      }
    }
  }
}

// Stolt Tabs for public pages
.PublicTabs{
  .tab-slider--tabs{ 
    min-height: 44px;
    border-bottom: 1px solid var(--theme-light-grey-color);
    li{  
      letter-spacing: 0.2px; 
      font-size: 16px;
      line-height: 24px; 
      font-weight: 400 !important; 
      padding: 8px 16px 12px 16px; 
      text-align: center; 
      display: flex;
      align-items: center;
      position: relative; 
      gap: 8px;
      color: var(--theme-text-color-02);
      &::before{ 
        left: 0; 
        @include media-breakpoint-only(md) {
          width: 100%;
        }
     }
      &.active{ 
        color: var(--primrose-yellow-color); 
        font-weight: 700 !important; 
        &::before{  
           background:var(--primrose-yellow-color); 
        }
      }
      &.AddMoreBtn{ 
        &::before{
          content: none;
        }
        svg{
          path{
            fill: var(--clr-secondary-public);
          }
        }
        div{
          align-items: center;
          display: flex;
          gap: 8px;
        }
      }
    } 
  }

}

.MonthYearlyDiv{
  .tab-slider--nav{
    .tab-slider--tabs{
      padding: 0 !important;
      border: none; 
    }
  }
}



.page-tab{
  &:hover{
    background: transparent !important;
  }
}