/* STOLT CSS VARIABLE  */
    
:root { 
    /* typography text color */
    --text-color-defolt: #008ECF;
    --theme-text-color-01: #27364B;  
    --clr-theme-01: #27364B;
    --theme-text-color-02: #64748B; 
    --theme-text-color-03: #1A1A1A; 
    --theme-text-color-04: #475569; 
    --theme-error-color: #CF0027; 
    --clr-theme-warn: #CF0027; 
    --theme-notify-color: #999999; 
    --theme-light-grey-color: #DBE3ED;
    --clr-white-common: #FFFFFF;
    --clr-grey-picker: #FAF4E5;
    --white-color: #FFFFFF;
    --neutralN10-color: #F1F4F9;
    --disabled-color: #CBD4E1;

    /* Front Colors Variable */
    --front-color-defolt: #041E42; 
    --front-primary-color: #041E42; 
    --front-textshadow-color: #00000061;
    --front-color-defolt02: #F2A900; 
    --primrose-yellow-color: #F2A900; 
    --front-color-defolt03: #63666A; 
    --front-color-defolt04: #9BA5B3; 
    --front-color-defolt05: #cdd2d9; 
    --front-color-defolt06: #B8BFCA; 
    --front-color-defolt07: #EDEDED; 
    --front-color-defolt08: #E9EEF2; 
    --Bg-color-defolt04: #F6F6F6; 
    --white-smoke-color: #F6F6F6; 
    --Bg-color-defolt05: #F6F8FC; 


    --clr-primary-public: #041E42; 
    --clr-secondary-public: #F2A900;
    --clr-secondary-public02: #FFA300;

    /** Stolt Pills Colors **/
    --PositiveValue: #15C69B;
    --NegativeValue: #FF8616;
    --DangerValue:#FF5F5F;
    --DangerValueRed:#FF1300;
    --DangerValueBlue:#0043EE;


    /* page background color */
    --content-background: #FAFAFA;
    --content-background-2: #F4F4F4;
    --content-background--02: #FAFAFA;
    --background--white: #FFF;

    /* Fonts */
    --font-family-01: "Roboto", sans-serif !important;
    --font-family-02: 'PT Serif', serif !important;
    --font-family-03: "Inter", sans-serif !important;
    --font-family-04: 'Lato', sans-serif !important;


    /* typography */
    --h1-font-size: 50px;
    --h1-line-height: 59px; 
    --h1-margin-bottom: 10px; 

    --h2-font-size: 38px;
    --h2-line-height: 45px;
    --h2-margin-bottom: 10px;

    --h3-font-size: 28px;
    --h3-font-weight: normal;
    --h3-line-height: 33px;
    --h3-margin-bottom: 10px; 

    --h4-font-weight: normal;
    --h4-font-size: 21px;
    --h4-line-height: 25px;
    --h4-margin-bottom: 14px;

    --h5-font-size: 12px;
    --h5-line-height: 14px;
    --h5-letter-spacing: 0.08em;
    --h5-text-transform: uppercase;
    --h5-margin-bottom: 16px;

    --p-font-size: 12px;
    --p-line-height: 14px;
    --p-letter-spacing: 0.08em;
    --p-text-transform: uppercase;
    --p-margin: 0px;
    --p-margin-bottom: 10px; 

    --small-font-weight: normal;
    --small-font-size: 12px;
    --small-line-height: 14px;

    /* alert */
    --error-color: #F03D3E;
    --error-background: #feecec;

    --success-background: #ebeefa;
    --success-color: #374FC7; 

    --notification-background: #fbede8; 
    --notification-color: #D84910;

    /* form */
    /* color */
    --yellow-background: #FAB000;
    --darkblue-background: #002044;
    --greydark-background: #41464B;

    --blackDrk-background: #212429;
    --greyfour-background: #495057;
    --greythree-background: #ACB5BD;
    --greytwo-background: #DDE2E5;
    --greyone-background: #F8F9FA;

    /*  ng-select */

    --ng-select-background: #FFFFFF; 
    --ng-select-border: 1px solid #CBD4E1;
    --ng-select-box-sizing: border-box;

    --ng-select-outline: none;
    --ng-select-box-shadow: none;
    --ng-select-font-style: normal;
    --ng-select-font-weight: normal;

    --ng-select-color: #27364B;
    --ng-select-text-transform: capitalize;

    --ng-select-background-image: url(../../assets/svg/appearance-arrow.svg); 
    --ng-select-background-image-2: url(../../assets/svg/appearance-arrow-2.svg);
    --ng-select-background-image-3: url(../../assets/svg/appearance-arrow-3.svg);
    --ng-select-background-image-3-active: url(../../assets/svg/appearance-arrow-3-active.svg);
    --ng-select-background-repeat: no-repeat;

    --ng-calendar-icon: url(../../assets/svg/calendar-icon.svg);
    --ng-calendar-icon-error: url(../../assets/svg/calendar-icon-error.svg);
    --ng-calendar-icon2: url(../../assets/svg/calendar-icon2.svg);
    --ng-calendar-icon2-active: url(../../assets/svg/calendar-icon2-active.svg); 
    
    --ng-select-display: block;
    --ng-select-position: relative;

    --ng-select-opened-border: 1px solid #4263EB;
    --ng-select-opened-background-image: url(../../assets/svg/appearance-arrow-up.svg);

    --CircleChecked: url(../../assets/icons/CircleChecked.svg);
    
    --SearchIconBG4: url(../../assets/icons/InputSearch.svg);
    --SearchIconBG: url(../../assets/icons/search-icon.svg);
    --SearchIconBG2: url(../../assets/icons/search-icon2.svg);
    --SearchIconBG3: url(../../assets/icons/search-icon3.svg);
    --SearchIconBG4: url(../../assets/icons/search-icon4.svg);
    --SearchIconBG5: url(../../assets/icons/search-icon5.svg);
    --tick-check: url(../../assets/icons/tick-check.svg);
    --radiobutton-icon-circle: url(../../assets/icons/radiobutton-icon-circle.svg);
    --radiobutton-icon-circle-active: url(../../assets/icons/radiobutton-icon-circle-active.svg);
    --radiobutton-icon: url(../../assets/icons/radiobutton-icon.svg);
    --radiobutton-icon-active: url(../../assets/icons/radiobutton-active.svg);

    --longcurrentdayline-bg: url(../../assets/icons/longcurrentdayline.svg);
    --three-dot-icon: url(../../assets/icons/ellipsis.svg);
    --close-large: url(../../assets/icons/close-large.svg); 
    

    /*  Stolt Img BG */
    --map-pin-icon: url(../../assets/svg/map-pin.svg);
    --reached-arrow-image: url(../../assets/svg/reached-arrow.svg);
    --dashed-border: url(../../assets/svg/dashed-border.svg);
    --test: url(../../assets/svg/test.png);
    --blur-map: url(../../assets/img/blur-map.png);
    --blur-map-2: url(../../assets/img/--blur-map-2.png);
    --cross-error: url(../../assets/icons/error-cross.svg);
    --disabled-error: url(../../assets/icons/disabled-cross.svg);
    --calendaricn: url(../../assets/icons/calendardemo.svg);

    /*  Front Design Lines Effect */
    --homebanner: url(../../assets/img/public/homebanner.webp);
    --homebanner-mobile: url(../../assets/img/public/homebanner-mobile.webp); 

    --homeBG-01: url(../../assets/svg/public/homeBG-01.svg);
    --ContactBGlines: url(../../assets/svg/public/ContactBGlines.svg);
    --ContactBGMobilelines: url(../../assets/svg/public/ContactBGMobilelines.svg);
    --lines-layer: url(../../assets/svg/public/lines-layer.svg);
    --aboutBGBorder: url(../../assets/svg/public/aboutBGBorder.svg);
    --aboutBGBorderMobile: url(../../assets/svg/public/aboutBGBorderMobile.svg);
    --aboutmobilelines: url(../../assets/svg/public/aboutmobilelines.svg);
    --snitsmobilelines: url(../../assets/svg/public/snitsmobilelines.png);
    --fleetBGlines: url(../../assets/svg/public/fleetBGlines.svg);
    --fleetBGMobilelines: url(../../assets/svg/public/fleetBGMobilelines.svg);
    --footerline01: url(../../assets/svg/public/footer-line01.svg);
    --Mobile-lines-footer: url(../../assets/svg/public/Mobile-lines-footer.svg);
    --footerline02: url(../../assets/svg/public/footer-line02.svg);
    --pluscorners: url(../../assets/svg/public/CornerPlus.svg);
    --accordion-arrow: url(../../assets/svg/arrow-up-down.svg);
    --cornerplusmobile: url(../../assets/svg/public/cornerplusmobile.svg);
}


