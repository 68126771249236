
/** --- Mixins for reusable property -- **/
@mixin bdr-radius-50 {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
 

/** --Stolt Pills Style-- **/
.CircleNotification{ 
  width: 12px;
  height: 12px; 
  border-radius: 50%;
  &.GreenCircle{
    background: #15C69B;
  }
  &.RedCircle{
    background: #F33A3A;
  }
  &.CircleOrange{
    background:#FFA216;
  }
}
.cargo-delay{ 
    padding: 0px 16px;
    height: 24px; 
    background: var(--background--white); 
    box-sizing: border-box;
    border-radius: 12px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    display: inline-flex;
    align-items: center; 
    justify-content: center;
    color: var(--PositiveValue);
    border: 1px solid  var(--PositiveValue);
    @media screen and (max-width: 992px) { 
      width: fit-content;
      border: 2px solid  var(--PositiveValue);
      font-weight: 700 !important;
    }
    &.PositiveValue{
      color: var(--PositiveValue);
      border-color: var(--PositiveValue);
    }
    &.NegativeValue{
      color: var(--NegativeValue);
      border-color: var(--NegativeValue);
    }
    &.DangerValue{
      color: var(--DangerValue);
      border-color: var(--DangerValue);
    }
    &.GreyValue{
      border: none;
      color: #27364B;
      background-color: #CBD4E1;
    }
  }


  .square_badge{
    font-family:var( --font-family-03);
    background: var(--background--white);
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 6px; 
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    &.badge_color_01{
      color: var(--DangerValueRed);
      border: 1px solid  var(--DangerValueRed);
    }
    &.badge_color_02{
      color: var(--DangerValueBlue);
      border: 1px solid  var(--DangerValueBlue);
    }
    
  }
  
  .badge_LD{  
      background: #E5F4FA;
      border-radius: 999px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #008ECF;
      font-style: normal;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 24px;
      &.floatingBadge{
        height: 24px;
        line-height: 16px;
        position: absolute;
        left: -19px;
        margin: 0 !important;
        top: 45px;
        //display: none;
        &.vesselBadge{
          @media screen and (min-width: 1023px) { 
          top: 50px;
          }
        }
        &.longCnt{
          left: -30px; 
            @media screen and (max-width: 992px) {  
              left: -48px; 
          }
        }
        @media screen and (max-width: 992px) {  
          left: -45px; 
          top: 67px;
        }
      }
  }

  .BadgeAdded{
    position: absolute;
    width: 59px;
    height: 16px;
    background: var(--text-color-defolt);
    border-radius: 36px;
    font-family:var( --font-family-03);
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: var(--white-color);
    justify-content: center;
    align-items: center;
    display: flex;
    left: -76px;
    margin-top: -20px;
    img{
      margin-right: 1px;
    }
  }

  .CountBadge{
    background: #CBD4E1; 
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
    color: var(--theme-text-color-01);
    font-weight: 700;
    margin-left: 8px;
    padding: 0 8px;
}

.CountBadgeSkltn{
  height: 20px;
  min-width: 30px;
  border-radius: 8px;
  margin-left: 8px;
}


/** Custom Classes For spacing **/
.Stolt-mb8{
  margin-bottom: 8px;
} 
.col_gap_8{
  column-gap: 8px;
}
.gap_16{
  gap: 16px;
}

.Stolt-mt-16{
  margin-top: 16px;
}
.Stolt-mt-32{
  margin-top: 32px;
} 
/** Custom Classes For spacing **/

/** Custom Classes For Width **/
.Custom-w-5{width: 5%;}
.Custom-w-10{width: 10%;}
.Custom-w-15{width: 15%;}
.Custom-w-20{width: 20%;}
.Custom-w-25{width: 25%;}
.Custom-w-30{width: 30%;}
.Custom-w-35{width: 35%;}
.Custom-w-40{width: 40%;}
.Custom-w-45{width: 45%;}
.Custom-w-50{width: 50%;}
.Custom-w-55{width: 55%;}
.Custom-w-60{width: 60%;}
.Custom-w-65{width: 65%;}
.Custom-w-70{width: 70%;}
.Custom-w-75{width: 75%;}
.Custom-w-80{width: 80%;}
.Custom-w-85{width: 85%;}
.Custom-w-90{width: 90%;}
.Custom-w-95{width: 95%;}
.Custom-w-100{width: 100%;}
.width-fc{width: fit-content;}

 /** Custom Classes For Width End**/




 

  .ngx-toastr { 
    box-shadow: none !important; 
}
.toast-container{ 
  position: fixed !important;
  z-index: 9999999 !important;
}

  /** Tooltip Custom Start **/
.ToolTipCustomHover{
  position: relative;
  &.LaycanLableHld{
    .TooltpIneerCntTt{
     @media screen and (max-width: 992px) { 
     top: 65px;
     width: 210px;
     right: 0;
     left: inherit;
    }
   }
 }
  img{
    cursor: pointer;
  }
  .IneerCntTt{
    position: absolute;
    background: var(--white-color);
    border: 1px solid var(--front-color-defolt07);
    box-sizing: border-box;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    min-width: 185px;  
    right: -20px;
    @media screen and (max-width: 992px) { 
      right: inherit;
      left: 40px;
    }
    top: 24px;
    padding: 16px 12px !important;
    &.ToolTipLaycanMain{
      padding: 16px !important;
      left: 0 !important;  
      border-radius: 8px;
      background: var(--white-color);  
      z-index: 99999 !important; 
      &.TooltipPointerAroow{
        &::before{
          left: 40px;
          border: 1px solid var(--front-color-defolt07);
        }
        &::after{
          left: 50px;
        }
        &.CargoLaycan{
          left: inherit !important;
          &::before {
            left: inherit;
            right: 50%;
          }
          &::after{
            left: inherit;
            right: 44%;
          }
        }
      }
    }
    z-index: 9;
     li{
      padding: 0 !important;
      width: 100% !important;
      text-align: center;
      span{
        margin-right: 0 !important;
      }
     }
     &.TooltpIneerCntTt{ 
      right: 0; 
      min-width: auto;
      @media screen and (max-width: 992px) { 
        right: inherit;
        left: 25px;
        top: 44px;
      }
      li {
        padding-right: 0 !important; 
        padding-left: 0;
        span{
          @media screen and (max-width: 992px) { 
            margin: 0 !important;
            padding: 0 !important;
          } 
        }
        .oLaycanGray{
          color: var(--theme-text-color-02);
        }
        .nLaycanBlack{
          color: var(--theme-text-color-01) !important;
        }
      }
      @media screen and (max-width: 992px) { 
      &.rightM{
        right: 0;
        left: inherit;
      }
    }
     }
  } 
}

.ToolTipLaycanMain{
  width: 400px;
  z-index: 999 !important;
  margin-top: 8px;
  @media screen and (max-width: 992px) { 
    left: inherit !important;
    right: 0 !important;
    top: 25px !important;
    width: 400px;
  }
  @media screen and (max-width: 767px) { 
    top: 56px !important;
    width: 320px;
  }  
  .TooltipLaycanBody{ 
    display: flex;
    flex-direction: column;
    gap: 16px; 
    .item{ 
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px; 
      @media screen and (max-width: 767px) { 
        align-items: flex-start;
      }
      &:first-child, &:last-child{
        margin-bottom: 0;
      }
      .headerTp{
        font-weight: 700 !important; 
      }
      span{ 
        color: var( --theme-text-color-01) !important;
        @media screen and (max-width: 767px) { 
          text-align: left;
        } 
      }
    }
  }
}

.ToolTipLaycanMainNew{
  .TooltipLaycanBody{
    display: flex;
    flex-direction: column;
    gap: 8px;
    .item{
      display: flex;
      gap: 72px;
      justify-content: space-between;
      .name{
        color: #64748B;
        font-size: 14px;
        &.DateTxt{
          font-weight: 700;
          color: #27364B;
        }
        &.headerTp{
          font-weight: 700; 
          &.DateTxt{
            color: #27364B; 
            &.headerTp{
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}


.TooltipPointerAroow{
  &::before{
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    background: var(--white-color);
    right: 20px; 
    top: -5px;
    transform: rotate(135deg);  
  }
  &::after{
    position: absolute;
    content: "";
    width: 30px;
    height: 16px;
    background: var(--white-color);
    top: 0;
    right: 30px; 
    transform: translate(-50%, 0); 
  }
}

  /** Tooltip Custom  End **/



  /** Blank Lable Start Here **/

  .BlankBG{  
    position: absolute !important;
    content: "";
    background: #E9EEF2; 
    width: 100%;
    height: 100%;
    &.BlankBGstatic{
      position: static !important; 
      min-height: 18px;
    }
    &.min-wdth{
      min-width: 130px;
    }
    &.min-wdth2{
      min-width: 25px;
    }
}
 /** Blank Lable End Here **/
 
 

 /** Properties With Important Tag **/
 
 /** Properties With Important Tag **/




 /* Messages  */
 @mixin ErorMsgText{
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--theme-notify-color) !important;
 }
 
.DataNotAvailable{
  margin-bottom: 70px;
  @media screen and (max-width: 767px) {
      text-align: center;
      margin-top: 30px;
  }  
  span{ 
    @include ErorMsgText;
 }
}
.BirthShedDataNotAvai{
  border-top: 1px solid #DBE3ED;
  text-align: center;
  padding-top: 72px;
}
.TotalRecordsMsg{
  padding: 10px 0px 20px 24px;
  @media screen and (max-width: 767px) {
    text-align: center;
    padding-left:0;
    &.norecordSus{
      display: flex;
      justify-content: center; 
    }
  }
  span{ 
    @include ErorMsgText;
 }
}
.SomethingRequiredMsg{
  @include ErorMsgText;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px; 
  padding-top: 30px;
}
/* Messages End */



.CapacityGraphM{
  .ngx-slider{
    .ngx-slider-selection{
      background: var(--front-color-defolt); 
    }
    .ngx-slider-pointer{ 
      background: var(--white-color);
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      width: 24px;
      height: 24px;
      top: -11px;
      outline: none;
      &::after{
        display: none;
      } 
    } 
    .ngx-slider-full-bar{
      .ngx-slider-bar{
        background: #CDD2D9;
        border-radius: 0;
      }
    }
     
  }
}


/* Custom Scrollbar Start Here */ 

.stolt__scrollbar{ 
  ::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      transition: all 1s ease-in-out;
  } 
  &.thincrollbar{
    ::-webkit-scrollbar {
      width: 4px; 
  } 
  }
  ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
  }
  ::-webkit-scrollbar-thumb {
      background: #DBE3ED;
      border: 0px none #ffffff;
      border-radius: 10px;
      &:hover, &:active{
          background: #c5c6ca;
      } 
  }
  ::-webkit-scrollbar-track {
      background: #F6F8FC;
      border: 0px none #ffffff;
      border-radius: 10px;
      &:hover, &:active{
          background: transparent;
      } 
  }
  ::-webkit-scrollbar-corner {
      background: transparent;
  }
}

 

.mainTop__scrollbarX{
  transform: rotateX(180deg); 
  .Top__scrollbarX{
    transform: rotateX(180deg); 
    &.HeightCntrl_M{
      @media screen and (max-width: 992px) {
        display: none;
      }
    }
  }
}


 

ng-select{
  .scroll-host {
    margin-right: 7px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track { 
      border-radius: 10px;
      /*box-shadow: inset 0 0 5px grey; */
    }
    &::-webkit-scrollbar-thumb {
      background: #CBD4E1;
      border-radius: 10px;
    } 
  }
  &.scroll-hostSpace{
    .scroll-host {
      margin-right: 0;
    }
  }
  &.customScroll2{
    .scroll-host { 
      margin-right: 4px; 
    }
  }
  &.space-rigt-0{
    .ng-dropdown-panel-items{
      padding-right: 0;
    }
  }
  &.HeightController{
    .ng-dropdown-panel-items{
      max-height: 165px !important;
      @media screen and (max-width: 767px) {
        max-height: 100px !important; 
      }
    }
    @media screen and (max-width: 767px) { 
      .ng-dropdown-panel { 
          left: inherit;
          right: 0;
          width: 288px;
      }
    }
  }
}

 

.CargoEtaInfo{ 
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track { 
    border-radius: 10px; 
  }
  &::-webkit-scrollbar-thumb {
    background: #CBD4E1;
    border-radius: 10px;
  }   
}
 
/* Custom Scrollbar End Here */ 

  .TootipDropDiv{
    z-index: 100;
    background: var(--white-color);
    border: 1px solid var(--front-color-defolt07); 
    border-radius: 8px;
    padding: 0;
    position: absolute;
    margin-top: 11px;
    max-width: 300px;
    &.agmmap__tp{
      max-width: 315px; 
    }

    &.m_emission__tbdtool{
      margin-top: 30px;
    }
    &.hideAr{
      margin-top: 4px;
      .AfetrBeforeAr{
        &::before{
          display: none;
        }
        &::after{
          display: none;
        }
      }

    }
    .AfetrBeforeAr{
      position: relative;
      padding: 16px;
      &::before{
        position: absolute;
        content: "";
        width: 15px;
        height: 15px;
        background: var(--white-color);
        left: 50%;
        top: -5px;
        transform: rotate(135deg);
        margin-left: -20px;
        border: 1px solid var(--front-color-defolt07);
      }
      &::after{
        position: absolute;
        content: "";
        width: 30px;
        height: 16px;
        background: var(--white-color);
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        margin-left: -10px;
      }
    }
    
    span{ 
      text-transform: none;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--theme-text-color-01);
      
    }
    &.MgoLsfo{ 
      max-width: 300px; 
      z-index: 99999;
      margin: 58px 0 0 -70px;
      @media screen and (min-width: 1440px) { 
        margin: 55px 0 0 60px; 
      }
      .AfetrBeforeAr{
        gap: 8px;
      }
      .itemtp{
        gap: 24px;
        span{
          color: var(--theme-text-color-01) !important;
        }
      }
    }
    &.LongTooltip{
      min-width: 327px;
      &.VesselTp{
        min-width: 250px; 
      }
      &.CargoTp{
        left: -105px;  
      }
      .HeaderToolt{
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--theme-text-color-01);  
        }
      }
      .BodyToot{
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{ 
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--theme-text-color-01); 
        }
      }
    }
    &.right__dir{
      right: 0;
      left: inherit;
      &.DataNotAvailablett{
        min-width: 286px;
        @media screen and (min-width: 1900px) { 
           right: 50px
        }
      }
      .AfetrBeforeAr{
        &::before{
          right: 50px;
          left: inherit;
        }
        &::after{
          left: inherit;
          top: -0.3px;
          right: 30px;
        }
      }
    }
    &.left__dir{ 
      .AfetrBeforeAr{
        &::before{ 
          left: 0;
          margin-left: 10px;
        }
        &::after{
          left: 0;
          margin-left: 20px;
        }
      }
    }
    &.Cargo__CharterTp{
      margin-top: 30px;
    }
    &.MidiumLongTooltip{
      min-width: 172px;
    }
    &.TradelaneWidth{
      min-width: 160px;
    }
    &.tradelineTp{
      min-width: 250px;
      top: 20px;
    }
    &.ssc__frmtp{ 
      top: 50px;
    }
    &.co2div{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .AfetrBeforeAr{ 
        &::before{ 
          margin-left: -10px; 
        }
        &::after{ 
          margin-left: -3px;
        }
      }
      span{ 
        &.mgo-holdr{
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &.nametxt{ 
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: var(--theme-text-color-02);
          min-width: 45px; 
          margin-right: 16px;
        }
      }
    }
  }

.tbd__txt{
  border-bottom: 1.5px dashed #27364B; 
} 

.Btm-dashed-bdr{
  border-bottom: 1.5px dashed var(--theme-text-color-01); 
} 

 .tbd__tooltip{
  .TootipDropDiv{ 
    max-width: 300px;
    left: 0; 
    margin-top: 30px;
    margin-left: -150px;
    &.TbdLastItem{
      margin-top: 36px;
    }
    &.vessal__tbd{
      margin-top: 10px;
      .AfetrBeforeAr {
        &::after{
          top: 0px;
        } 
    }
    }
  }
 } 

.TooltipCommonMn{
  padding-left: 120px;
  position: absolute;
  z-index: 9;
  top: -4px;
  display: none;
  &.cargosTltp{
    padding-left: 30px;
    .col-doc{
      max-width: 130px;
    }
  }
  &.btm-direction{
    top: inherit; 
    bottom: -5px;
    .TooltipCommonIn{
      &::before{
        top: inherit; 
        bottom: 8px;
      }
      &::after{
        top: inherit; 
        bottom: 5px;
      }
    }
  } 
  .TooltipCommonIn{
    position: relative;
    &::before{
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      background: var(--white-color);
      left: 14px;
      top: 8px;
      transform: rotate(135deg);
      margin-left: -20px;
      border: 1px solid var(--front-color-defolt07);
      z-index: 9;
    }
    &::after{
      position: absolute;
      content: "";
      width: 15px;
      height: 20px;
      background: var(--white-color);
      top: 5px;
      left: 23px;
      transform: translate(-50%, 0);
      margin-left: -15px;
      z-index: 9;
    }
  } 
 .TooltipCommon{  
      padding: 16px;
      width: 352px;
      background: var(--white-color);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px; 
      position: relative;  
      max-height: 300px;
      overflow-y: auto; 
      &.w-max-content{
        width: max-content;
        .ToolTipmain{
          .col-doc{
            width: fit-content;
          }
        }
      }
      &.TooltipCommon2{
        width: 245px;
        .col-main{
          .col-doc{
            width: 130px;
          }
          .col-load{
            width: 50px; 
            text-align: center
          }
        }
      }
    .col-main{
      display: flex;
      column-gap: 10px;
      flex-direction: column;
      row-gap: 2px;
      .col-doc{
        width: 230px;
      }
      .col-load{
        width: 63px;
        svg{
          margin-left: 6px;
        }
      }
      .col-disc{
        width: 70px;
        svg{
          margin-left: 26px;
        }
      }
      .header-tp{
        display: flex;
        column-gap: 8px;
        span{
          color: var(--theme-text-color-01);
          letter-spacing: 0.2px;

        }
      }
      .body-tp{ 
        .body-tp-row{
          display: flex;
          column-gap: 8px;
          border-bottom: 1px solid #F1F4F9;
          padding: 2px 0;
          span{
            color: var(--theme-text-color-01);
            letter-spacing: 0.2px; 
          }
        } 
      }
    }
 }
}

.tooltip-mngR{
  position: relative;
  .agmmap__tp{
    top: inherit;
    bottom: 32px;
    margin-top: 0px;
    min-width: 315px;
    .AfetrBeforeAr{
      &::before{
      bottom: -8px;
      top: inherit;
      }
      &::after{
        top: inherit;
        bottom: 0;
      }
    }
}
  
}
 .tooltipHvr{
    width: fit-content;
    position: relative;
    &:hover{
      .TooltipCommonMn{
        display: block;
      }
    }
 }
 .BorderDotted{
    border-bottom: 1.5px dashed;
    cursor: pointer;
 }

  /* HIGHCHARTS CUSTOMIZATION START */
  .highcharts-chart-holder{
    width:100%;
    padding: 0px 16px;
  }
  .highcharts-credits{
    display: none;
  }
  /* HIGHCHARTS CUSTOMIZATION END */

 

  /* COMMON STYLE DESIGN */ 
  .Download__xlsMain{
    @media screen and (max-width: 992px) {
      flex-direction: column;
    } 
    .Download__xls{
      color: var(--primary-default);
      padding: 0 var(--spacing-24);
      text-decoration: none;
      font-weight: 700; 
      @media screen and (max-width: 992px) {
        padding: 0;
        margin-bottom: var(--spacing-8);
      }
    }
  }
  .form-group{
    .FiltersTitle{ 
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;  
      color: var(--theme-text-color-01);
      height: 48px;
      display: flex;
      align-items: center; 
      .Stolt-Skeleton{
        min-width: 120px;
      }
      // .filter-reset{
      //     color: var(--text-color-defolt);
      //     text-decoration: none;
      //     margin-left: 8px;
      //     font-weight: 400;
      //     min-width: 90px;
      //     &.minWauto{
      //       min-width: auto;
      //     }
      // }
      .filter-reset{
          color: var(--text-color-defolt);
          text-decoration: none;
          margin-left: 8px;
          width: 67px; 
          a{
            text-decoration-line: underline;
            color: var(--text-color-defolt); 
            opacity: 1; 
          }
          &.anchor-disable{
            a{ 
             color: #B8BFCA;
            }
          } 
      }
    }
  }

  .anchor-disable {
    color: currentColor;
    cursor: not-allowed;
    text-decoration: none;
    a{
      pointer-events: none;
      color: #B8BFCA;
    }
}

  .modal-active {
    opacity: 1 !important;
    display: block !important;
    background-color: rgba(0, 0, 0, 0.4509803922);
  }
  .input-full-width{ 
      .SearchField{ 
          width: 70% !important;
          @media screen and (max-width: 767px) {
            width: 100% !important;
          }
      }
      .FieldSearch{
        @media screen and (max-width: 767px) {
          height: 110px !important;
          background-position: right 16px top 62px !important;
        .mobile-label{ 
            top: 58px !important;
        }
        .FieldSearchIn{
          flex-direction: column;
          .SelectedTag{
            width: fit-content;
          }
        }
        .ClearCross { 
            top: 59px !important; 
        }
      }
      }
  }
  .transform-inherit{
    transform: inherit !important;
  }

.common-deropdown{
  padding: 8px 0 4px 0px;
  width: 221px;
  background: #FFFFFF;
  box-shadow: 0px 10px 15px rgba(15, 26, 42, 0.1);
  border-radius: 4px;
  position: absolute;
  left: 0;
  z-index: 99;
  display: none;
  ul{
    li{
      border-bottom: 1px solid #F1F4F9;
      height: 52px;
      padding: 0 16px;
      .checkboxHld{
        align-items: center;
        position: relative;
        height: 100%;
        .containerCheckbx {
          top: 0 !important;
          padding: 0px 0px 0px 39px;
        }
      }
    }
  }
}

.drop-down-edit{
  .form-control{
    border: none;
    padding: 0;
    background: none;
    ng-select{
      border: none;
      background-color: transparent;
      background-image: var(--three-dot-icon);
      background-repeat: var(--ng-select-background-repeat);
      background-size: 20px;
      background-position: right 4px top 4px; 
      height: auto;
      width: fit-content; 
      &.super_admin_dots{
        .ng-value-label{
          line-height: 24px;
        }
        .ng-input{
          input{
            display: none;
          }
        }
      }
      .ng-select-container {
        &.ng-has-value{
          .ng-value-container{
            font-weight: 400;
          } 
        }
      } 
      .ng-dropdown-panel{
        min-width: 167px;
        max-width: 200px;
        border: none;
        margin: 4px 0 0 0; 
        .ng-dropdown-panel-items{
          margin: 0;
          padding: 0; 
          max-height: inherit !important;
          .ng-option {
            padding: 0 16px; 
            border-bottom: 1px solid #F1F4F9;
            margin-top: 4px;
            &.ng-option-selected{  
              .checkTk{
                background-image: var(--tick-check) !important;
                font-weight: 700; 
                background-repeat: no-repeat;
                background-position: center left;
              }
            }
            &:last-child {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
  .CheckBxMn{
    .checkboxHld{
      .containerCheckbx{
        top: 14px !important;
      }
    }
    .ItemName{
      padding-left: 35px;
    }
  }
  .checkTkSpace{
    padding-left: 36px;
  }
} 

.hideLastItem{
  ng-dropdown-panel{
    .ng-dropdown-panel-items{
      .ng-option{
        &:last-child{
          display: none !important;
        }
      }
    }
  }
}

 /* mat dialog customization */ 
.mat-dialog-container { 
  padding: 0 !important;
  border-radius: 0 !important; 
  overflow: hidden !important; 
  box-shadow: none;
  background: none;
}

.StickySec{
  @media screen and (min-width: 993px) {
    position: sticky;
    top: 0;
    z-index: 9;
    background: var(--white-color);
  }
}

  /* COMMON STYLE DESIGN END */ 



  /* Snl Model Customization */
  .CenterModelCmn{
    .wrapper{
      max-height: 100% !important;
      .modal{
        margin: 0 auto;
        position: relative;
        .modal__header{
          height: 88px;
          .modal__header-content{
            display: flex;
            align-items: center;
          }
          .modal__header-icon{
            snl-icon{
              background: var(--close-large) no-repeat center;
              width: 32px;
              height: 48px; 
              outline: none;
              .fa-close{
                display: none;
              }
            }
          }
        }
      } 
    }

    /* SNL Model customization Notification properties */
    &.NotifiPropMdl{
      .wrapper{
        .modal{
          &.modal__wrapper-medium{
            width: 1000px;
            height: calc(100% - 80px);
          }
        }
      }
    }

    /* SNL Model customization Delete */
    &.DeleteModelSnl{
      .wrapper{
        .modal{
          &.modal__wrapper-small{
            min-width: 400px; 
            .modal__header-icon{
              align-items: center;
            }
            .body-b{
              font-size: 16px;
              line-height: 24px;
            }
            @media screen and (max-width: 992px) {
              width: calc(100% - 24px) !important;
              border-radius: var(--spacing-4) !important;
              min-width: auto;
              max-height: inherit;
              height: 230px !important;
            }
          }
        }
      }
    }
    &.SetActiveModelSnl{
      .wrapper{
        .modal{
          &.modal__wrapper-small{
            .modal__header{
              max-height: 88px;
            }
            .modal__content-small{
              max-height: 154px;
            }
            min-width: 400px; 
            max-width: 400px;
            @media screen and (max-width: 767px) {
              min-width: calc(100% - 8px);
              max-width: 100%;
              height: 350px !important;
            }
            .BodySetActive{
              display: flex;
              flex-direction: column;
              gap: 24px;
              p{
                margin: 0;
              }
            }
          }
        }
      }
    }

    /* SNL Model customization Fleet Enquiry */
    &.EnquiringMdl{
      .modal{
          width: 792px !important;
          border: 1px solid #D5DCE0;
          box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06) !important;
          border-radius: 4px !important;
          padding-top: 32px;
          height: calc(100% - 80px) !important;
          max-height: 570px;
          @media screen and (max-width: 992px) {
            width: 100% !important;
            border: none; 
            border-radius: 0 !important; 
            height: 100% !important;
            padding-top: 16px;
            max-height: max-content;
            .modal__content-medium{
              padding: 0 16px !important;
            }
          }
          .BannerMainEnq{
            @media screen and (max-width: 992px) {
              display: flex;
              flex-direction: column-reverse;
              row-gap: 16px;
            }
          }
          .BannerAreaHld{
            height: 250px;
            @media screen and (max-width: 992px) {
              height: auto;
              max-height: 600px;
            }
            margin-bottom: 16px;
            border-radius: 4px;
            overflow: hidden;
          }
          .ReferenceSec{
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
          .modal__footer{
            padding-bottom: 32px !important;
            @media screen and (max-width: 992px) { 
              padding: 24px 0 !important;
              display: flex;
              justify-content: center;
            }
            snl-button{
              width: 143px;
              display: flex;
              outline: none;
              .snl-button{
                width: 100%;
              }
            }
          }
          .modal__header{
              display: none !important;
              @media screen and (max-width: 992px) {
                display: block !important;
                position: absolute;
                right: 16px;
                top: 28px;
                padding: 0 !important;
                height: auto !important;
                .modal__header-icon{
                  snl-icon{
                    width: 20px !important;
                    height: 20px !important;
                    background-color: #fff;
                    border-radius: 50%;
                    background-size: 20px;
                  }
                }
              }
          }
      }
      .wrapper{
        opacity: 1;
        background: rgba(100, 116, 139, 0.33);
      }
    }
  }
 
  /* Customise My View */
  .CustomiseMyView__modal{
    .modal{
      max-width: 600px;
      height: 100% !important;
      max-height: 530px; 
      ul{
        li{
          &.CustomMview{
            padding: var(--spacing-12) 0;
            .containerCheckbx{
              display: flex;
              align-items: center; 
              cursor: pointer;
            }
            .container__radio--description{
              margin-left: var(--spacing-16);
              word-break: break-word;
              .RadioLabel{
                padding-left:0;
                cursor: pointer;
              }
            } 
          }
        }
        &.AdditionalList{
          margin-top: var(--spacing-24) !important;
          .container__checkbox--description{
            margin-left: 0;
          }
          .RadioLabel{
            padding-left: var(--spacing-16) !important;           
            color: var(--font-color-primary);
          }
        }
      }
      @media screen and (max-width: 992px) {
        max-height: 540px !important; 
      }
    } 
  }


  /* Common Mobile Model Customization */
  .CommonPhoneCstm{ 
    @media screen and (max-width: 992px) {
      .modal{ 
          flex-direction: column !important;
          width: calc(100% - 8px) !important; 
          height: calc(100% - 90px) !important; 
          border-radius: 8px !important;
          .modal__content-medium{
            padding-bottom: 0 !important;
          }
      } 
      .wrapper{
        z-index: 999999 !important; 
        .modal__header{
          order: inherit !important;
        }
        .modal__footer{ 
          height: auto !important;
          margin-bottom: 0 !important; 
          max-height: inherit !important;
      }
      }
      &.AddUser__model{
        .modal{  
          .modal__content-medium{
            padding-bottom: 24px !important;
          }
      } 
      }
    }
  }

  /* Customise Share berths' schedules */
  .BerthsSchedulesMdl{
    .wrapper{
        .modal{
            max-width: 520px;
            height: calc(100% - 80px) !important;
            max-height: 540px !important;
            @media screen and (max-width: 767px) {
              max-height: 580px !important;
            }
            .modal__header{
              height: auto !important;
              padding-bottom: 0 !important;
              .berths-sub-heading{
                display: flex;
                padding-top: var(--spacing-8);
              }
            }
            .scroll{
              &.modal__content-medium{
                padding-top: var(--spacing-24) !important;
              }
            }
        }
    }
  } 


 