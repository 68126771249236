
// Third-Party CSS Styles for Information Page, Did not make any change in this file. 
#spectaql pre {
    overflow: auto;
    margin-top: 0;
    margin-bottom: 20px;
    color: #fff;
}

#spectaql table {
    width: 100%;
    table-layout: fixed;
    text-align: left;
    border-collapse: collapse
}

#spectaql table td,
#spectaql table th {
    margin: 0;
    padding: 0
}

#spectaql #introduction .example-section>*,
#spectaql .definition-heading,
#spectaql .doc-heading,
#spectaql .introduction-item-title,
#spectaql .operation-heading {
    overflow: hidden;
    text-overflow: ellipsis
}

#spectaql #page {
    display: flex
}

#spectaql #page * {
    box-sizing: border-box
}

#spectaql #page.drawer-open #sidebar {
    z-index: 1000;
    transform: translateX(0)
}

#spectaql #page.drawer-open .drawer-overlay {
    display: block;
    background: rgba(0, 0, 0, .5);
    z-index: 10
}

#spectaql #sidebar {
    position: fixed;
    min-width: 250px;
    max-width: 250px;
    flex-shrink: 0;
    transition: transform .2s ease-out;
    transform: translateX(-100%);
    z-index: 10;
    padding-top: 20px;
    background: #fff;
    padding-bottom: 0;
}

@media (min-width:48em) {
    #spectaql #sidebar {
        position: relative;
        transform: none
    }
}

@media (min-width:64em) {
    #spectaql #sidebar {
        min-width: 300px;
        max-width: 300px
    }
}

#spectaql .sidebar-top-container {
    display: flex;
    align-items: center;
    padding: 0 20px
}

#spectaql #mobile-navbar {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background: #fff;
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px
}

@media (min-width:48em) {
    #spectaql #mobile-navbar {
        display: none
    }
}

#spectaql .sidebar-open-button {
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    border: none;
    background: 0 0
}

#spectaql .sidebar-open-button .hamburger {
    width: 16px;
    height: 14px;
    cursor: pointer
}

#spectaql .sidebar-open-button .hamburger::after {
    display: block;
    content: "";
    height: 2px;
    background: #222;
    box-shadow: 0 5px 0 #222, 0 10px 0 #222
}

#spectaql .sidebar-open-button .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

#spectaql .close-button .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

@media (min-width:48em) {
    #spectaql .close-button {
        display: none
    }
}

#spectaql .drawer-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0)
}

@media (min-width:48em) {
    #spectaql .drawer-overlay {
        display: none !important
    }
}

#spectaql #nav {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 0px);
    padding: 0 20px;
    padding-bottom: 20px;
    position: sticky;
    top: 0;
    overflow: auto
}

#spectaql #logo {
    margin-right: auto
}

#spectaql #logo img {
    display: block;
    width: 100%;
    max-width: 100%
}

#spectaql .nav-group-items,
#spectaql .nav-group-section-items {
    padding: 0;
    margin: 0
}

#spectaql .nav-group-items>li,
#spectaql .nav-group-section-items>li {
    list-style: none
}

#spectaql .nav-group-items .nav-group-section-title,
#spectaql .nav-group-items>li,
#spectaql .nav-group-section-items .nav-group-section-title,
#spectaql .nav-group-section-items>li {
    overflow: hidden;
    text-overflow: ellipsis
}

#spectaql .nav-group-section-items {
    display: none
}

#spectaql .nav-scroll-expand .nav-group-section-items {
    display: block
}

#spectaql #docs {
    position: relative;
    margin: 0 auto;
    min-width: 100px;
    max-width: 88em;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 20px
}

@media (min-width:48em) {
    #spectaql .doc-row {
        display: flex;
        flex-wrap: wrap
    }
}

#spectaql .doc-row .doc-copy,
#spectaql .doc-row .doc-examples {
    width: 100%
}

@media (min-width:48em) {

    #spectaql .doc-row .doc-copy,
    #spectaql .doc-row .doc-examples {
        width: 50%
    }
}

@media (min-width:48em) {
    #spectaql .doc-row .doc-copy {
        padding-right: 20px
    }
}

@media (min-width:48em) {
    #spectaql .doc-row .doc-examples {
        padding-left: 20px
    }
}

.hljs {
    display: block;
    overflow-x: auto;
    padding: .5em;
    background: #23241f
}

.hljs,
.hljs-subst,
.hljs-tag {
    color: #f8f8f2
}

.hljs-emphasis,
.hljs-strong {
    color: #a8a8a2
}

.hljs-bullet,
.hljs-link,
.hljs-literal,
.hljs-number,
.hljs-quote,
.hljs-regexp {
    color: #ae81ff
}

.hljs-code,
.hljs-section,
.hljs-selector-class,
.hljs-title {
    color: #a6e22e
}

.hljs-strong {
    font-weight: 700
}

.hljs-emphasis {
    font-style: italic
}

.hljs-attr,
.hljs-keyword,
.hljs-name,
.hljs-selector-tag {
    color: #f92672
}

.hljs-attribute,
.hljs-symbol {
    color: #66d9ef
}

.hljs-class .hljs-title,
.hljs-params {
    color: #f8f8f2
}

.hljs-addition,
.hljs-built_in,
.hljs-builtin-name,
.hljs-selector-attr,
.hljs-selector-id,
.hljs-selector-pseudo,
.hljs-string,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
    color: #e6db74
}

.hljs-comment,
.hljs-deletion,
.hljs-meta {
    color: #75715e
}

#spectaql {
    font-family: -apple-system, BlinkMacSystemFont, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 1.6;
    background: #fff;
    color: #222;
    padding: 0;
    margin: 0
}

@media (min-width:32em) {
    #spectaql {
        font-size: 16px
    }
}

#spectaql a {
    color: #0298bf;
    text-decoration: none
}

#spectaql a:hover {
    color: #0182a2
}

#spectaql a:active,
#spectaql a:focus {
    color: #0298bf
}

#spectaql code {
    font-size: .875em;
    font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace
}

#spectaql pre code {
    background: #222;
    display: block;
}

#spectaql pre code,
#spectaql pre code.hljs {
    font-size: .82em;
    line-height: 1.4;
    padding: 15px 20px
}

#spectaql .doc-heading {
    line-height: 1.2;
    font-size: 2.25em;
    margin-top: 10px
}

@media (min-width:48em) {
    #spectaql .doc-heading {
        margin-top: -10px
    }
}

#spectaql .close-button {
    background: 0 0;
    border: none;
    padding: 5px;
    font-size: 16px;
    font-weight: 700;
    color: #222;
    display: block;
}

#spectaql #introduction {
    margin-bottom: 60px
}

#spectaql #introduction .example-section:not(.example-section-is-code) {
    margin-bottom: 20px
}

#spectaql #introduction .example-section:not(.example-section-is-code) h5,
#spectaql #introduction .example-section:not(.example-section-is-code) p {
    margin: 0;
    font-size: 1em
}

#spectaql #docs,
#spectaql #mobile-navbar
{
    padding-top: 20px;
    padding-bottom: 20px
}

@media (min-width:32em) {

    #spectaql #docs,
    #spectaql #mobile-navbar,
    #spectaql #sidebar {
        padding-top: 30px;
        padding-bottom: 30px
    }
}

@media (min-width:48em) {

    #spectaql #docs,
    #spectaql #mobile-navbar,
    #spectaql #sidebar {
        padding-top: 40px;
        padding-bottom: 40px
    }
}

#spectaql #docs,
#spectaql #mobile-navbar,
#spectaql #nav,
#spectaql .sidebar-top-container {
    padding-left: 20px;
    padding-right: 20px
}

@media (min-width:32em) {

    #spectaql #docs,
    #spectaql #mobile-navbar,
    #spectaql #nav,
    #spectaql .sidebar-top-container {
        padding-left: 30px;
        padding-right: 30px
    }
}

@media (min-width:48em) {

    #spectaql #docs,
    #spectaql #mobile-navbar,
    #spectaql #nav,
    #spectaql .sidebar-top-container {
        padding-left: 50px;
        padding-right: 50px
    }
}

#spectaql #sidebar a {
    color: #222
}

#spectaql #sidebar a.nav-scroll-active,
#spectaql #sidebar a:hover {
    font-weight: 700
}

#spectaql #sidebar a.nav-scroll-active {
    color: #222
}

#spectaql #sidebar a:hover {
    color: #0182a2
}

@media (min-width:48em) {
    #spectaql #sidebar {
        border-right: 2px solid #d8d8d8
    }
}

@media (min-width:32em) {
    #spectaql #mobile-navbar {
        margin-top: -30px;
        margin-left: -30px;
        margin-right: -30px
    }
}

#spectaql #mobile-navbar .sidebar-open-button::after {
    display: block;
    content: "All Topics";
    margin-left: 10px;
    color: #222
}

#spectaql #nav .nav-group {
    margin-top: 20px
}

#spectaql #nav .nav-group li {
    margin-bottom: 5px
}

#spectaql #nav .nav-group-title {
    font-size: .875em;
    font-weight: 400;
    margin: 0 0 6px 0;
    color: #999
}

#spectaql #nav .nav-group-section-title {
    font-size: inherit;
    margin: 0;
    margin-bottom: 5px;
    font-weight: 400
}

#spectaql #nav .nav-group-section-items {
    margin-left: .75em
}

#spectaql .definition,
#spectaql .operation {
    margin-bottom: 60px
}

#spectaql .definition .definition-heading,
#spectaql .definition .operation-heading,
#spectaql .operation .definition-heading,
#spectaql .operation .operation-heading {
    font-size: 1.72em
}

#spectaql .definition .definition-heading code,
#spectaql .definition .operation-heading code,
#spectaql .operation .definition-heading code,
#spectaql .operation .operation-heading code {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit
}

@media (min-width:32em) {

    #spectaql .definition .definition-heading,
    #spectaql .definition .operation-heading,
    #spectaql .operation .definition-heading,
    #spectaql .operation .operation-heading {
        font-size: 2.25em
    }
}

#spectaql .definition-group-name,
#spectaql .group-heading,
#spectaql .operation-group-name {
    border-top: 2px solid #d8d8d8;
    padding-top: 3px;
    color: #999;
    font-size: inherit;
    font-weight: inherit
}

#spectaql .definition-group-name a,
#spectaql .group-heading a,
#spectaql .operation-group-name a {
    color: #999
}

#spectaql .definition-group-name a:hover,
#spectaql .group-heading a:hover,
#spectaql .operation-group-name a:hover {
    font-weight: 700
}

#spectaql .doc-examples {
    margin-top: 20px
}

#spectaql .doc-examples .example-heading {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

#spectaql .doc-examples .example-section-is-code h5 {
    color: #999;
    text-transform: uppercase;
    background: #000;
    font-size: .75em;
    font-weight: 700;
    padding: .6em 0 .6em 20px;
    margin: 0;
    opacity: 1
}

@media (min-width:48em) {
    #spectaql .doc-examples {
        margin-top: 0
    }
}

#spectaql .doc-copy p {
    margin: 0 0 20px 0
}

#spectaql .doc-copy p:last-child {
    margin-bottom: 0
}

#spectaql .doc-copy table tr th {
    font-weight: 400;
    border-bottom: 2px solid #d8d8d8
}

#spectaql .doc-copy table tr td {
    border-bottom: 1px solid #e0e0e0
}

#spectaql .doc-copy table tr.row-has-field-arguments td,
#spectaql .doc-copy table tr:last-child td {
    border-bottom: none
}

#spectaql .doc-copy table tr td,
#spectaql .doc-copy table tr th {
    padding: 5px
}

#spectaql .doc-copy table tr td:first-child,
#spectaql .doc-copy table tr th:first-child {
    padding-left: 0
}

#spectaql .doc-copy table tr td:last-child,
#spectaql .doc-copy table tr th:last-child {
    padding-right: 0
}

#spectaql .doc-copy .doc-copy-section {
    margin-bottom: 30px
}

#spectaql .doc-copy .doc-copy-section>h5 {
    margin: 0 0 5px 0;
    font-size: inherit;
    font-weight: inherit;
    color: #999
}

#spectaql .doc-copy .definition-description>h5,
#spectaql .doc-copy .definition-properties>h5,
#spectaql .doc-copy .operation-description>h5 {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

#spectaql .field-arguments {
    font-size: .875em;
    background-color: #fafbfc;
    border: 1px solid #e0e0e0;
    padding: 10px;
    margin-bottom: 5px
}

#spectaql .field-arguments p {
    margin: 10px 0 0 0
}

#spectaql .field-arguments h5.field-arguments-heading {
    margin: 0;
    padding: 0 0 10px 0;
    font-weight: inherit;
    color: #999
}

#spectaql .field-arguments .field-argument {
    border-top: 1px #e0e0e0 solid;
    padding: 10px 0
}

#spectaql .field-arguments .field-argument:last-child {
    padding-bottom: 0
}

#spectaql .field-arguments .field-argument-name {
    margin: 0;
    font-size: inherit;
    font-weight: inherit
}

#spectaql .deprecation-reason {
    word-break: break-word
}

#spectaql .deprecation-reason::before {
    display: inline;
    content: "Deprecated";
    padding: 2px 5px;
    margin-right: 5px;
    background: #fed7d8;
    color: #c60609;
    font-weight: 700;
    font-size: .875em
}