
// Stolt paragraps 
.CommonText{
    font-family: var(--font-family-01);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;  
} 

.SubText{
    color: var(--theme-text-color-02);
    font-style: normal; 
    font-size: 12px;
    line-height: 16px; 
    letter-spacing: 0.2px;
    font-weight: 400;
    margin-top: 4px;
    strong{
        font-weight: 700;
    }
}

// Stolt public pages common paragraps
.CommonPara{
    font-family: var(--font-family-01); 
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;  
    color: var(--front-color-defolt03); 
    margin: 16px 0px 0px 0px;
} 









