
// Import breakpoints mixin from mixin-breakpoints.scss
@import "src/styles/common/mixin-breakpoints.scss";

@mixin common-st-font {
  font-style: normal;
  font-weight: normal;
  margin: 0;
}
 

h1, h2, h3, h4, h5, h6{
  font-family: var(--font-family-02); 
}

.heading01{ 
    @include common-st-font;  
    font-weight: 700;
    font-size: 26px;
    line-height: 34px; 
    color: var(--clr-theme-01);  
}

.heading02{ 
  @include common-st-font; 
}

.heading03{
  @include common-st-font; 
  font-weight: 700; 
  letter-spacing: -0.006em;
  @include media-breakpoint-only(md) {
    font-size: 24px;
    line-height: 30px;
  }
 }

.heading03-front{
    color: var(--clr-primary-public);
    font-size: 36px;
    line-height: 48px; 
    @include media-breakpoint-only(md) { 
      font-size: 24px;
      line-height: 30px;
    }
 }

.heading04{  
    @include common-st-font;
    font-size: 16px;
    line-height: 21px;   
}

.heading05{
  font-style: normal;
}

.heading06{ 
  @include common-st-font; 
}

.heading026{ 
  @include common-st-font; 
  font-weight: 700;
  font-size: 26px;
  line-height: 40px; 
  letter-spacing: 0.2px;  
 }


// Stolt Standard font sizes    
.font-24{
  font-size: 24px;
  line-height: 28px !important;
}
.font-20{
  font-size: 20px !important;
  line-height: 32px !important;
}
.font-18{
  font-size: 18px;
  line-height: 24px;
}
.font-16{ 
  font-size: 16px !important;
  line-height: 24px;
}

.font-14{
  font-size: 14px;
  line-height: 18px;
}

// Stolt Standard font weight
.fw-400{font-weight: 400;}
.fw-500{font-weight: 500;}
.fw-600{font-weight: 600;}
.fw-700{font-weight: 700 !important;}


// Stolt Truncate text
.truncate-text{
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 30px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}



// Override and customize the styles for an accordion 
.accordion{
  .BorderBtmClass{
    border-bottom: 1px solid var(--front-color-defolt08);
    &:last-child{
      border-bottom: none;
    }
  }
  .accordion-item{
    border: none;
    border-radius: 0 !important; 
    .accordion-header{ 
      .accordion-button{
        box-shadow: none;
        background: none;
        padding: 0 30px 0 24px !important;
        &.setting-accordion{
          padding: 0 0 32px 0 !important;
        }
        &.min-heightbkg{
          min-height: 30px;
        }
        position: relative;
        z-index: 999;
        &.collapsed{
          padding-bottom: 0 !important;
        }
        &::after{
          position: absolute;
          right: 30px;
          top: 18px;
          @include media-breakpoint-only(md) { 
            top:12px;
          }
          background-image: var(--accordion-arrow);
          background-size: 15px;
          transition: none;
        }
        &:not(.collapsed)::after{ 
          top: 5px !important;
          right: 34px !important;
          @include media-breakpoint-only(md) {
            top:1px !important;
            right: 24px !important;
          }
        }
        &.AroowSkeltonHld{
          &::after{
            display: none;
          }
          .AroowSkelton{
            position: absolute;
            width: 15px;
            height: 10px;
            right: 35px;
            top: 4px;
          }
        }
         
      }
      &.DocumentationAcord{
        .accordion-button{
          &::after{ 
            top: 24px !important; 
            background-position: center;
          }
          &:not(.collapsed)::after{ 
            top: 24px !important; 
            right: 30px !important;
          }
        }
      }
    }
  }
}



// SNL page title common 
snl-page-title{
  .container__page-title{
    padding: 0 0 var(--spacing-32) 0 !important;
    @include media-breakpoint-only(md) {
      padding: 0 0 var(--spacing-4) 0 !important; 
    }
    .header-1{
      color: var(--font-color-primary);
    } 
    .content__body--left{
      gap: 0;
    }
  }
}